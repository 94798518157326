import React from 'react'
import { DiscordIcon } from '../../../Icons'

const HeroSection = () => {
    return (
        <div className='flex items-center sm:justify-between bg-[#1A1B1A] z-0 max-lg:px-[8vw] lg:pl-[8vw] relative min-h-[90dvh] gap-[24px] max-lg:flex-col'>
            <div className="flex flex-col gap-[24px] max-w-[602px] sm:py-[42px] w-full">
                <h1 className='font-[700] text-[#FFF] xl:text-[52px] 2xl:text-[62px] text-[36px] max-lg:text-center'>
                    Utilising <span className='text-[#15C8ED]'>High-Frequency</span> Trading for every investor
                </h1>
                <p className='text-[#D0D0D0] text-[20px] xl:text-[24px] font-[400] max-lg:text-center'>
                    Try Eternity Panther: Earn Consistent <span className='text-[#FFF] font-[500]'>2.5%</span> Returns Every 24 Hours. Access High-Frequency Trading with Minimum Capital Requirements
                </p>
                <div className="flex max-xl:flex-col items-start gap-[16px]">
                    <button className="font-[500] px-[36px] py-[12px] rounded-[8px] bg-[#15C8ED] text-[#FFF] text-[14px] sm:text-[18px] w-full text-center items-center">
                        Start Trial
                    </button>
                    <button className="flex items-center gap-[10px] font-[500] px-[36px] py-[12px] rounded-[8px] border-[1px] border-[#15C8ED] text-[#FFF] text-[14px] sm:text-[18px] w-full text-center justify-center">
                        <DiscordIcon />
                        Join Our Discord
                    </button>
                </div>
            </div>
            <img src="/images/heroImage.png" alt="" className='max-lg:max-h-[400px] block lg:max-w-[50%] max-w-full 2xl:max-w-[900px]' />
            <img src="/images/heroDoodles.png" className='absolute top-0 right-0' alt="" />
        </div>
    )
}

export default HeroSection