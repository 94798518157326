import React from 'react'
import { GreetsIcon, GroupIcon, PointerIcon, PriceIcon, ReloadIcon, SupportIcon } from '../../../Icons'

const RightSection = () => {
    return (
        <div className='flex flex-col gap-[48px] px-[8vw] py-[60px]'>

            <div className="flex flex-col gap-[10px] items-center">

                <span className='text-[18px] sm:text-[24px] text-[#10B6D8] font-[500s] text-center'>
                    Eternity Trade
                </span>

                <div className="flex flex-col gap-[16px] items-center">

                    <h2 className='lg:text-[42px] text-[32px] xl:text-[52px] text-[#090B17] font-[700] text-center'>
                        See Why We Are the Right Partner for You
                    </h2>

                    <p className='text-[#6B7280] text-[18px] text-center'>
                        New active traders enjoy a complimentary one-month trial of EterniPanther. Try it yourself with 0 Cost
                    </p>

                </div>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-[32px]">

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <PointerIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                Quick and easy registration. Get started in minutes!
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            With FundedNext, you do not have to wait hours to get access to your trading account. FundedNext Traders instantly get access to their trading account as soon as they complete the signup process.
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                        Eternity Trade
                    </span>

                </div>

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <ReloadIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                Reset Topup
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            At FundedNext, we provide the opportunity to restart your trading journey, even if you have violated any rules. You can reset your trading balance at a discounted price with these options.
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                        Earn Rewards Daily
                    </span>

                </div>

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <SupportIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                24/7 Support
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            If you're navigating our website or engaging with our community on Discord and Telegram, assistance is just a message away. Our dedicated support team is always available to address your queries, provide guidance, and ensure a smooth experience every step of the way.
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                        Daily Stability, Daily Growth
                    </span>

                </div>

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <GroupIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                Monthly Competition
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            Participate in engaging daily games on our channels or get daily rewards while trading. Enjoy the thrill of winning while expanding your portofolio!
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                        More Friends, More Cash
                    </span>

                </div>

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <PriceIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                Fastest Reward
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            Earn daily income, by generating consistent profits on your trades. Earn 2.5% net from your trades, while Eternity receives a 30% cut from your Profits. Win-Win partnership!
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                    </span>

                </div>

                <div className="flex flex-col gap-[32px] p-[24px] items-center shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] border-[1px] border-[#E5E7EB] rounded-[16px]">

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex items-center gap-[24px]">
                            <div className="border-[#E5E7EB] border-[1px] rounded-[12px]  flex items-center justify-center w-[72px] h-[72px] shadow-[4px_4px_24px_0px_rgba(97,91,238,0.16)]">
                                <GreetsIcon />
                            </div>
                            <h4 className='text-[20px] sm:text-[24px] text-[#090B17] font-[500]'>
                                Best Trading Condition
                            </h4>
                        </div>
                        <p className='text-[#6B7280]'>
                            You'll earn 10% on Level 1, 6% on Level 2, and 4% on subsequent levels (Level 3, Level 4, Level 5). Your cut is automatically added each time your teammembers complete a trade.
                        </p>
                    </div>

                    <span className='underline text-[#10B6D8] font-[600]'>
                    </span>

                </div>

            </div>

        </div>
    )
}

export default RightSection