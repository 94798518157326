import React from 'react'

function Footer() {
  return (
    <div className='flex max-xl:flex-col items-start footerBg py-[56px] px-[5.556vw] gap-[32px]'>

      <div className="flex items-start flex-col gap-[24px] max-w-[424px]">

        {/* <img src="/images/footerLogo.png" alt="" /> */}

        <div className="flex items-center gap-[16px]">
          <img src="/images/logo.gif" className='w-[50px]' alt="" />
          <span className='text-[36px] mb-[-8px] font-[500] text-[#FFF]'>
            ETERNITY
          </span>
        </div>

        <p className='font-[400] text-[20px] text-[#FFFFFF70] '>
          Eternity’s Panther is not just a quantitative investment platform; it's a certified hub for professional quantitative investment strategies. Our commitment to transparency and excellence is backed by certifications and licenses that validate our expertise.
        </p>

        <div className="flex items-center gap-[16px]">
          <img src="/images/icons/facebook.png" alt="" className='transition hover:opacity-75 cursor-pointer' />
          <img src="/images/icons/instagram.png" alt="" className='transition hover:opacity-75 cursor-pointer' />
          <img src="/images/icons/twitter.png" alt="" className='transition hover:opacity-75 cursor-pointer' />
          <img src="/images/icons/youtube.png" alt="" className='transition hover:opacity-75 cursor-pointer' />
        </div>

      </div>

      <div className="md:grid md:grid-cols-3 flex flex-col w-full gap-[32px]">

        <div className="flex flex-col gap-[20px] row-span-2">

          <h2 className='text-[24px] text-[#FFF] font-[500]'>
            Important Links
          </h2>

          <div className="flex flex-col gap-[12px]">
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              FAQ
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Affiliate Partner
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Payment Partner
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Terms of Service
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Privacy Policy
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Cookie Policy
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Risk Disclosure
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              AML Policy
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Contact Us
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Server Status
            </span>
          </div>

        </div>

        <div className="flex flex-col gap-[20px]">

          <h2 className='text-[24px] text-[#FFF] font-[500]'>
            Models
          </h2>

          <div className="flex flex-col gap-[12px]">
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Express Model
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Evaluation Model
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Stellar Challenge
            </span>
          </div>

        </div>

        <div className="flex flex-col gap-[20px]">

          <h2 className='text-[24px] text-[#FFF] font-[500]'>
            Education
          </h2>

          <div className="flex flex-col gap-[12px]">
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Economic Calendar
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Trading Firm
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Blog
            </span>
          </div>

        </div>

        <div className="flex flex-col gap-[20px]">

          <h2 className='text-[24px] text-[#FFF] font-[500]'>
            Community
          </h2>

          <div className="flex flex-col gap-[12px]">
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Discord Community
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Facebook Community
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Telegram Community
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Instagram Community
            </span>
          </div>

        </div>

        <div className="flex flex-col gap-[20px]">

          <h2 className='text-[24px] text-[#FFF] font-[500]'>
            Contacts
          </h2>

          <div className="flex flex-col gap-[12px]">
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              support@fundednext.com
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Live Chat
            </span>
            <span className='text-[#FFFFFF70] cursor-pointer transition-all hover:text-[#FFF] text-[18px]'>
              Messenger
            </span>
          </div>

        </div>

      </div>

    </div>
  )
}

export default Footer