import React from 'react'

const InfrastructureSection = () => {
    return (
        <div className="flex items-center flex-col gap-[48px] px-[8vw] py-[60px] w-full">
            <div className="flex flex-col gap-[16px]">
                <span className='text-[20px] text-[#10B6D8] text-center font-[500]'>
                    Eternity Trade
                </span>
                <h1 className='text-[32px] text-clip xl:text-[52px] font-[700] text-[#090B17] text-center'>
                    Our Infrastructure, Traders' Accomplishments!
                </h1>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 md:w-[70%]">
                <div className="flex items-center md:gap-[26px] w-full">
                    <img src="/images/infrastructure/world.png" className='mt-[16px] w-[100px] sm:w-[180px]' alt="" />
                    <div className="flex flex-col gap-[4px]">
                        <h1 className='text-[32px] xl:text-[48px] font-[700] bg-gradient-to-r from-[#0093E9] to-[#80D0C7] bg-clip-text text-transparent'>
                            100
                        </h1>
                        <span className='text-[#5F5F65] font-[500] text-[18px]'>
                            Countries Covered
                        </span>
                    </div>
                </div>
                <div className="flex items-center md:gap-[26px] w-full">
                    <img src="/images/infrastructure/badge.png" className='mt-[16px] w-[100px] sm:w-[180px]' alt="" />
                    <div className="flex flex-col gap-[4px]">
                        <h1 className='text-[32px] xl:text-[48px] font-[700] bg-gradient-to-r from-[#0093E9] to-[#80D0C7] bg-clip-text text-transparent'>
                            $5M
                        </h1>
                        <span className='text-[#5F5F65] font-[500] text-[18px]'>
                            Total Payouts
                        </span>
                    </div>
                </div>
                <div className="flex items-center md:gap-[26px] w-full">
                    <img src="/images/infrastructure/time.png" className='mt-[16px] w-[100px] sm:w-[180px]' alt="" />
                    <div className="flex flex-col gap-[4px]">
                        <h1 className='text-[32px] xl:text-[48px] font-[700] bg-gradient-to-r from-[#0093E9] to-[#80D0C7] bg-clip-text text-transparent'>
                            30 Minutes
                        </h1>
                        <span className='text-[#5F5F65] font-[500] text-[18px]'>
                            Withdrawal Time
                        </span>
                    </div>
                </div>
                <div className="flex items-center md:gap-[26px] w-full">
                    <img src="/images/infrastructure/world.png" className='mt-[16px] w-[100px] sm:w-[180px]' alt="" />
                    <div className="flex flex-col gap-[4px]">
                        <h1 className='text-[32px] xl:text-[48px] font-[700] bg-gradient-to-r from-[#0093E9] to-[#80D0C7] bg-clip-text text-transparent'>
                            10k+
                        </h1>
                        <span className='text-[#5F5F65] font-[500] text-[18px]'>
                            EterniTraders
                        </span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default InfrastructureSection