import React from 'react'

const FutureSection = () => {
    return (
        <div className="flex flex-col gap-[20px] px-[8vw] py-[35px] items-center">

            <div className="flex flex-col gap-[10px] items-center">

                <span className='text-[20px] text-[#10B6D8] text-center font-[500]'>
                    Eternity Trade
                </span>

                <div className="flex flex-col gap-[16px]">
                    <h1 className='text-[32px] text-clip xl:text-[52px] font-[700] text-[#090B17] text-center'>
                        Project your future income based on your data.
                    </h1>
                    <p className='text-[#6B7280] text-[18px] text-center'>
                        FundedNext aims to be the best partner for you. That is why we have carefully thought of each and every aspect of a trader’s journey with FundedNext. Here are a few points which set us apart.
                    </p>
                </div>

                <div className="flex items-center">
                    
                </div>
                
            </div>

        </div>
    )
}

export default FutureSection