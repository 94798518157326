import React from 'react'
import { DiscordIcon, TelegramIcon, WhatsappIcon } from '../../../Icons'

const GlobalSection = () => {
    return (
        <div className='flex flex-col items-center gap-[42px] globalBg px-[8vw] py-[60px]'>
            <div className="flex flex-col gap-[16px]">
                <h1 className='text-center lg:text-[36px] text-[32px] xl:text-[46px] font-[700] text-[#FFF]'>
                    Join the Global Community for the Traders, by the Traders
                </h1>
                <p className='text-[#FFF] text-[18px] sm:text-[20px] text-center'>
                    Join our channel group to get access first to new updates, gameplays & exclusive trading resources. Connect with like-minded traders, share what you’re up to, and be the first to know about platform updates. Come and join the conversation!
                </p>
            </div>
            <div className="flex items-center justify-center gap-[16px] flex-wrap">
                <span className="flex items-center gap-[12px] px-[24px] py-[10px] rounded-[8px] text-[#FFF] cursor-pointer hover:opacity-75 transition-all">
                    <WhatsappIcon />
                    <span className='underline font-[600]'>
                        WhatsApp Community
                    </span>
                </span>
                <span className="border-[1px] border-[#15C8ED] flex items-center gap-[12px] px-[24px] py-[10px] rounded-[8px] text-[#FFF] cursor-pointer hover:scale-95 transition-all">
                    <DiscordIcon />
                    <span className='font-[600]'>
                        Join Our Discord
                    </span>
                </span>
                <span className="bg-[#15C8ED] flex items-center gap-[12px] px-[24px] py-[10px] rounded-[8px] text-[#FFF] cursor-pointer hover:scale-95 transition-all">
                    <TelegramIcon />
                    <span className='font-[600]'>
                        Telegram Community
                    </span>
                </span>
            </div>
        </div>
    )
}

export default GlobalSection