import React from 'react'
import HeroSection from './components/HeroSection';
import CompanySection from './components/CompanySection';
import FundSection from './components/FundSection';
import EternityTrade from './components/EternityTrade';
import InfrastructureSection from './components/InfrastructureSection';
import CommunitySection from './components/CommunitySection';
import TradingSection from './components/TradingSection';
import GuranteedSection from './components/GuranteedSection';
import FutureSection from './components/FutureSection';
import RightSection from './components/RightSection';
import GlobalSection from './components/GlobalSection';

const Home = () => {
    return (
        <>
            <HeroSection />
            <CompanySection />
            <FundSection />
            {/* <EternityTrade /> */}
            <InfrastructureSection />
            <CommunitySection />
            <TradingSection />
            <GuranteedSection />
            <FutureSection />
            <RightSection />
            <GlobalSection />
        </>
    )
}

export default Home;