import ReactRoute from "./reactRoute/ReactRoute";

function App() {
  return (
    <>
      <span className="z-[1] max-lg:hidden absolute w-[50vw] h-[100dvh] bg-[#FFF] right-0 bgHero opacity-10"></span>
      <ReactRoute />
    </>
  );
}

export default App;