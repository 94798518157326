import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./RouteConstants";

import Home from "../pages/home/Home.jsx";
import Navbar from "../components/navbar/Navbar.jsx";
import Footer from "../components/footer/Footer.jsx";

const ReactRoute = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path={ROUTES.home} element={<Home />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};

export default ReactRoute;