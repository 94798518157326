import React from 'react'

const GuranteedSection = () => {
    return (
        <div className='flex items-center gap-[48px] bg-[#222154] px-[8vw] py-[60px] max-md:flex-col'>
            <img src="/images/Guranteed.png" alt="" />
            <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[12px]">
                    <span className='text-[18px] lg:text-[20px] xl:text-[24px] text-[#80E0F4] max-md:text-center'>
                        Grow Your Earnings with EterniPanther's Referral Program:
                    </span>
                    <h2 className='lg:text-[36px] text-[32px] xl:text-[46px] text-[#FFF] font-[600] max-md:text-center'>
                        Guaranteed Rewards!
                    </h2>
                </div>
                <p className='text-[#FFF] text-[16px] sm:text-[18px] leading-8 max-md:text-center'>
                    EterniPanther rewards you for spreading the word.
                    <br />
                    Here's how:
                    Tell others about Eterni Panther using your unique code & build your Team. As your team grows, so does your earnings.
                    <br />
                    You'll earn:
                    <br />
                    10% from your Level 1 members (direct referrals).6% from Level 2 (referrals of your Level 1 members).4% from Level 3, 4 & 5 (referrals of your Level 2 and beyond).
                    <br />
                    The more friends you invite and the more they invite others, the more capital you add to your portofolio. It's that simple!
                </p>
            </div>
        </div>
    )
}

export default GuranteedSection