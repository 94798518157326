import React from 'react'

const CompanySection = () => {
    return (
        <div className='flex items-center justify-between gap-[32px] bg-[#1C2728] py-[16px] px-[8vw]'>
            <img src="/images/companies/okk.png" className='max-w-[80px] lg:max-w-[120px] xl:max-w-[160px] 2xl:max-w-[200px]' alt="" />
            <div className='h-[42px] w-[1px] bg-[#F8F8F8] max-lg:hidden'></div>
            <img src="/images/companies/coinbase.png" className='max-w-[80px] lg:max-w-[120px] xl:max-w-[160px] 2xl:max-w-[200px]' alt="" />
            <div className='h-[42px] w-[1px] bg-[#F8F8F8] max-lg:hidden'></div>
            <img src="/images/companies/binance.png" className='max-w-[80px] lg:max-w-[120px] xl:max-w-[160px] 2xl:max-w-[200px]' alt="" />
            <div className='h-[42px] w-[1px] bg-[#F8F8F8] max-lg:hidden'></div>
            <img src="/images/companies/bitGet.png" className='max-w-[80px] lg:max-w-[120px] xl:max-w-[160px] 2xl:max-w-[200px] max-sm:hidden' alt="" />
        </div>
    )
}

export default CompanySection