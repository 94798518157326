import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './CommunitySection.css';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

const CommunitySection = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className='flex flex-col gap-[48px] py-[60px]'>

            <h2 className='lg:text-[42px] text-[32px] xl:text-[48px] font-[700] text-[#090B17] text-center'>
                Tutorials and videos by Community
            </h2>

            <div className="flex flex-col items-center gap-[40px]">

            <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={36}
                    breakpoints={{
                        0: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 20,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 24,
                            spaceBetween: 50,
                        },
                    }}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="flex items-center justify-center px-[8vw] max-h-[100px] mySwiper w-[100%]"
                >
                    <SwiperSlide className='min-w-[42px] sm:min-w-[68px] rounded-[8px] overflow-hidden'>
                        <img src="/images/communityThumbnail/communityThumbnail1.png" />
                    </SwiperSlide>
                    <SwiperSlide className='min-w-[42px] sm:min-w-[68px] rounded-[8px] overflow-hidden'>
                        <img src="/images/communityThumbnail/communityThumbnail2.png" />
                    </SwiperSlide>
                    <SwiperSlide className='min-w-[42px] sm:min-w-[68px] rounded-[8px] overflow-hidden'>
                        <img src="/images/communityThumbnail/communityThumbnail3.png" />
                    </SwiperSlide>

                </Swiper>
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }}
                    slidesPerView={1.2}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 1,
                        },

                        1024: {
                            slidesPerView: 1.2,
                        },
                    }}
                    initialSlide={1}
                    centeredSlides
                    spaceBetween={65}
                    watchSlidesProgress={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                >
                    <SwiperSlide>
                        <iframe width="1903" height="776" src="https://www.youtube.com/embed/Wv7wNBhsw18" title="Eternity Trade — Company Introductory Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </SwiperSlide>
                    <SwiperSlide>
                        <iframe width="1903" height="776" src="https://www.youtube.com/embed/wETUTxmZrK4" title="ETERNITY-TRADE ARBITRAGE PLATFORM | Up to 28% Commissions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </SwiperSlide>
                    <SwiperSlide>
                        <iframe width="1903" height="776" src="https://www.youtube.com/embed/ipvHi0uEUwM" title="Mastering the Art of Operation with Eternity Panther for Quantitative Trading" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </SwiperSlide>
                </Swiper>

            </div>

        </div>
    )
}

export default CommunitySection