import React from 'react'

const TradingSection = () => {
    return (
        <div className='relative flex flex-col gap-[48px] px-[8vw] py-[60px]'>

            <div className="flex flex-col items-center gap-[16px]">
                <h1 className='lg:text-[42px] text-[32px] xl:text-[52px] font-[700] text-[#090B17] text-center'>
                    EterniPanther Trading Strategies
                </h1>
                <p className='text-[#6B7280] text-[18px] text-center'>
                    Learn about EP's Dynamic Trading Strategies & how it works
                </p>
            </div>

            <div className="flex items-center gap-[24px] max-sm:flex-col">
                <div className="flex items-center justify-center p-[24px] shadow-[3px_3px_10px_0px_rgba(0,0,0,0.08)] rounded-[8px] border-[1px] border-[#5D67E4]">
                    <img src="/images/tradingStategy/introducing.png" alt="" className='w-[320px]' />
                </div>
                <div className="flex flex-col gap-[16px]">
                    <h2 className='text-[28px] text-[#10B6D8] font-[500]'>
                        Introducing EterniPanther:
                    </h2>
                    <p className='text-[20px] text-[#6B7280]'>
                        Your High-Frequency Trading (HFT) Robot. It utilises cutting-edge technology and advanced trading algorithms; EterniPanther executes lightning-fast trades, leveraging quantitative strategies to secure returns in the cryptocurrency market.
                    </p>
                </div>
            </div>

            <div className="flex items-center gap-[24px] max-sm:flex-col-reverse">
                <div className="flex flex-col gap-[16px]">
                    <h2 className='text-[28px] text-[#10B6D8] font-[500]'>
                        EterniPanther's Key Features:
                    </h2>
                    <p className='text-[20px] text-[#6B7280]'>
                        <li>
                            Free Monthly Subscription: Start trading with zero upfront fees. Get a complimentary monthly subscription when you join EterniPanther, giving you instant access to its powerful tools.
                        </li>
                        <li>
                            Earn Big every 24 Hours: See real results fast. EterniPanther generates impressive returns, with a 2.5% ROI achievable within just one day of trading.
                        </li>
                        <li>
                            Flexibility Built In: Customize your trading strategy with EterniPanther's flexible cycles, ranging from short-term 2-hour trades to longer 48-hour sessions.
                        </li>
                    </p>
                </div>
                <div className="flex items-center justify-center p-[24px] shadow-[3px_3px_10px_0px_rgba(0,0,0,0.08)] rounded-[8px] border-[1px] border-[#5D67E4]">
                    <img src="/images/tradingStategy/eterniPanther'sKey.png" alt="" className='w-[320px]' />
                </div>
            </div>

            <div className="flex items-center gap-[24px] max-sm:flex-col">
                <div className="flex items-center justify-center p-[24px] shadow-[3px_3px_10px_0px_rgba(0,0,0,0.08)] rounded-[8px] border-[1px] border-[#5D67E4]">
                    <img src="/images/tradingStategy/eterniPanther.png" alt="" className='w-[320px]' />
                </div>
                <div className="flex flex-col gap-[16px]">
                    <h2 className='text-[28px] text-[#10B6D8] font-[500]'>
                        How EterniPanther generates consistent profit?
                    </h2>
                    <p className='flex flex-col gap-[20px] text-[20px] text-[#6B7280]'>
                        EterniPanther makes money in crypto by playing the price differences between exchanges. It's like buying low on one platform (like OKX) and selling high on another (like Binance). Here's how:
                        <li>
                            Smart Strategies: EterniPanther uses different tricks to maximize profits, like:
                        </li>
                        <span className='ml-[24px]'>
                            - Arbitrage: Buying low and selling high to lock in gains.
                        </span>
                        <span className='ml-[24px]'>
                            -Market Making: Being the go-between for buyers and sellers, making a profit on the difference.
                        </span>
                        <span className='ml-[24px]'>
                            -Statistical Tricks: Using quantitative trading algorithms to spot trends and find good trades.
                        </span>
                    </p>
                </div>
            </div>

            <img className='absolute right-0 top-0' src="/images/tradingStrategies.png" alt="" />
        </div>
    )
}

export default TradingSection