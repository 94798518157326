import { MenuIcon } from "../../Icons"

function Navbar() {
    return (
        <div className="flex items-center justify-between gap-[32px] px-[8vw] py-[24px] bg-[#1A1B1A] border-b-[1px] border-b-[#B7B7A430]">

            <img className="w-[40px]" src="images/logo.gif" alt="" />

            <div className="flex items-center gap-[36px] z-[1] relative">

                <div className="flex items-center gap-[24px] max-sm:hidden">

                    <span className="cursor-pointer hover:opacity-80 transition-all font-[500] text-[18px] text-[#FFF]">
                        Home
                    </span>

                    <div className="h-[22px] w-[2px] bg-[#E7E7E7]"></div>

                    <span className="cursor-pointer hover:opacity-80 transition-all font-[400] text-[18px] text-[#FFF]">
                        FAQ
                    </span>

                    <div className="h-[22px] w-[2px] bg-[#E7E7E7]"></div>

                    <span className="cursor-pointer hover:opacity-80 transition-all font-[400] text-[18px] text-[#FFF]">
                        Be a Partner
                    </span>

                    <div className="h-[22px] w-[2px] bg-[#E7E7E7]"></div>

                    <span className="cursor-pointer hover:opacity-80 transition-all font-[400] text-[18px] text-[#FFF]">
                        About Us
                    </span>

                    <div className="h-[22px] w-[2px] bg-[#E7E7E7]"></div>

                </div>

                <div className="flex items-center sm:gap-[32px] gap-[12px]">

                    <MenuIcon iconColor="#FFF" onClick={() => { }} className={"cursor-pointer sm:hidden"} />

                    <span className="cursor-pointer transition-all hover:opacity-80 text-[#FFFFFF] text-[14px] sm:text-[18px] font-[500]">
                        Sign Up
                    </span>

                    <button className="font-[500] px-[36px] py-[12px] rounded-[4px] bg-[#15C8ED] text-[#FFF] text-[14px] sm:text-[18px]">
                        Login
                    </button>

                </div>

            </div>

        </div>
    )
}

export default Navbar